<template>
    <div class="user-home">
        <div class="user-center-banner">
        <div class="user-box">
            <div class="avatar-box">
                <img :src="userInfo.photo || '' " alt="" class="user-avatar">
            </div>
            <h1 class="user-name" v-text="userInfo.clientname || userInfo.clientno"></h1>
        </div>
        </div>
        <ul class="tabbar">
            <li class="tab-item-w">
                <router-link to="/user/booking/order" class="tab-item">
                    <img src="../../../../assets/imgs/mobile/user_center/icon_dingdan@2x.png" alt="" class="tab-icon">
                    <span class="tab-title">預約訂單</span>
                </router-link>
            </li>
            <li class="tab-item-w">
                <router-link to="/user/booking/record" class="tab-item">
                    <img src="../../../../assets/imgs/mobile/user_center/icon_bingli@2x.png" alt="" class="tab-icon">
                    <span class="tab-title">歷史病歷</span>
                </router-link>
            </li>
            <li class="tab-item-w" v-if="isDoctorUser">
                <router-link to="/user/association/myActivity" class="tab-item">
                    <img src="../../../../assets/imgs/mobile/user_center/icon_xuehui@2x.png" alt="" class="tab-icon">
                    <span class="tab-title">學會活動</span>
                </router-link>
            </li>
            <li class="tab-item-w">
                <router-link to="/user/aq-news/question" class="tab-item">
                    <img src="../../../../assets/imgs/mobile/user_center/icon_wenda@2x.png" alt="" class="tab-icon">
                    <span class="tab-title">我的問答</span>
                </router-link>
            </li>
            <!-- <li class="tab-item-w">
                <router-link to="/user/aq-news/saved" class="tab-item">
                    <img src="../../../../assets/imgs/mobile/user_center/icon_shouc@2x.png" alt="" class="tab-icon">
                    <span class="tab-title">我的收藏</span>
                </router-link>
            </li> -->
        </ul>
        <ul class="menus">
            <li class="menu-w">
                <router-link to="/user/userInfo" class="menu">
                    <span class="menu-title">用戶信息维护</span>
                    <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-arrow">
                </router-link>
            </li>
            <li class="menu-w">
                <router-link to="/user/updatePwd" class="menu">
                    <span class="menu-title">修改密碼</span>
                    <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-arrow">
                </router-link>
            </li>
            <li class="menu-w">
                <router-link to="/user/card/plan" class="menu">
                    <span class="menu-title">醫療計劃</span>
                    <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-arrow">
                </router-link>
            </li>
            <li class="menu-w">
                <router-link to="/user/card/service" class="menu">
                    <span class="menu-title">服務卡</span>
                    <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-arrow">
                </router-link>
            </li>
            <li class="menu-w">
                <router-link to="/user/resume" class="menu">
                    <span class="menu-title">我的履歷</span>
                    <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-arrow">
                </router-link>
            </li>
            <li class="menu-w">
                <router-link to="/user/applyJobs" class="menu">
                    <span class="menu-title">我的職位申請</span>
                    <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-arrow">
                </router-link>
            </li>
            <!-- <li class="menu-w" v-if="isDoctorUser">
                <router-link to="/user/aq-news/news" class="menu">
                    <span class="menu-title">我的資訊</span>
                    <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-arrow">
                </router-link>
            </li> -->
            <!-- <li class="menu-w" v-if="isDoctorUser">
                <router-link to="/user/aq-news/addNews" class="menu">
                    <span class="menu-title">發佈資訊</span>
                    <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-arrow">
                </router-link>
            </li> -->
        </ul>
    </div>
</template>

<script>
	export default {
		data() {
			return {
                isDoctorUser: false,
                userInfo: this.$store.getters.userInfo
			}
        },
        mounted(){
            this.isDoctorUser = !! (this.userInfo && this.userInfo.doctorglobaluserid)
            
        }
	}
</script>

<style lang="scss" scoped>
    .user-center-banner {
        margin: 11px 2px 0px 24px;
        height: 194px;
        background-image: url(../../../../assets/imgs/mobile/user_center/img_bannergeren@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .user-center-banner > .user-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 194px;
        justify-content: center;
    }

    .user-center-banner > .user-box .avatar-box {
        display: inline-block;
        width: 108px;
        height: 108px;
        border-radius: 50%;
        background-color: #969696;
    }

    .user-center-banner > .user-box .user-avatar {
        display: inline-block;
        width: 108px;
        height: 108px;
        border-radius: 50%;
    }

    .user-center-banner > .user-box .user-avatar[src=''] {
        display: none;
    }

    .user-center-banner > .user-box > .user-name {
        color: #fff;
        font-size: 28px;
        font-weight: 400;
        margin-top: 15px;
    }

    .tabbar {
        display: flex;
        flex-direction: row;
        height: 140px;
        justify-content: space-evenly;
        background-color: #fff;
        margin: 16px 24px 0 24px;
    }

    .tabbar > .tab-item-w {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

    }

    .tabbar > .tab-item-w .tab-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 23px;
    }

    .tabbar > .tab-item-w .tab-item > .tab-icon {
        width: 37px;
        height: 45px;
        margin-bottom: 21px;
    }

    .tabbar > .tab-item-w:nth-child(2) .tab-item > .tab-icon {
        width: 46px;
        height: 46px;
        margin-bottom: 20px;
    }

    .tabbar > .tab-item-w:nth-child(3) .tab-item > .tab-icon {
        width: 46px;
        height: 39px;
        margin-bottom: 24px;
    }

    .tabbar > .tab-item-w:nth-child(4) .tab-item > .tab-icon {
        width: 46px;
        height: 44px;
        margin-bottom: 21px;
    }

    .tabbar > .tab-item-w:nth-child(5) .tab-item > .tab-icon {
        width: 48px;
        height: 40px;
        margin-bottom: 24px;
    }

    .tabbar > .tab-item-w .tab-item > .tab-title {
        color: #636363;
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
    }

    .menus {
        display: flex;
        flex-direction: column;
    }

    .menus > .menu-w > .menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #fff;
        height: 88px;
        margin-left: 24px;
        margin-right: 24px;
        padding-left: 26px;
        padding-right: 30px;
        border-top: 1px solid #ededed;
        align-items: center;
    }

    .menus > .menu-w > .menu > .menu-title {
        color: #231F20;
        font-size: 32px;
        font-weight: bold;
    }

    .menus > .menu-w:nth-child(1) > .menu {
        margin-top: 16px;
    }

    .menus > .menu-w:nth-child(3) > .menu {
        margin-top: 8px;
    }

    .menus > .menu-w:nth-child(5) > .menu {
        margin-top: 8px;
    }

    .menus > .menu-w > .menu > .icon-arrow {
        display: inline-block;
        width: 8px;
        height: 17px;
    }
</style>